<template>
  <Loader class="mt-6 flex justify-center" v-if="showLoaderComp" />
  <div v-else class="flex flex-col justify-center items-center">
    <div
      class="mt-6 w-full hidden md:block lg:hidden justify-center items-center"
    >
      <img
        class="h-72 m-auto"
        src="@/assets/images/perpetual-logo.svg"
        alt="perpetual-logo"
      />
    </div>
    <HeaderGlobal titlemenu1="Notification Settings" />
    <!-- v-if="petoverviewTab != 1 || petTab1.length > 0" -->
    <div class="w-full mt-2 pl-0 pt-8 text-left font-proximaMedium">
      <div>
        <h3 class="mb-4 text-left text-primary text-lg">
          In-App Notifications
        </h3>
        <div
          class="w-full mb-2 px-4 py-4 bg-white border-2 border-primary rounded-lg flex justify-between"
            v-for="(item, i) in settings" :key="i"
        >
          <span>{{item.name}}</span>
          <RadioOnoffBorderVue
            :val="{item}"
            :checked="item.checked"
            @inputVal="getInputVal"
          />
          <!-- <RadioOnoffBorderVue @inputVal="getInputVal" /> -->
        </div>
      </div>
    </div>
    <div class="mb-6 w-full flex justify-center">
      <BorderedButtonVue
        class="mr-1"
        title="Cancel"
        width="w-4/12"
        paddingX="px-2"
      />
      <submit-button
        class="ml-1"
        title="Save"
        background="bg-primary"
        width="w-4/12"
        paddingX="px-2"
      />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import FormError from "../../warning/FormError.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButtonVue from "../../buttons/BorderedButton.vue";
import Loader from "../../loader/Loader.vue";
import ArrowNext from "../../icons/ArrowForwardIos.vue";
import TimeAgoVue from "../../timeago/TimeAgo.vue";
import InputRadioEachReverseVue from "../../forms/InputRadioEachReverse.vue";
import RadioOnoffBorderVue from "../../forms/RadioOnoffBorder.vue";

export default {
  components: {
    FormError,
    HeaderGlobal,
    Loader,
    SubmitButton,
    BorderedButtonVue,
    ArrowNext,
    TimeAgoVue,
    InputRadioEachReverseVue,
    RadioOnoffBorderVue,
  },
  data() {
    return {
      settings: [
        {
          no: 1,
          name: "Contact Requests",
          checked: false,
        },
        {
          no: 2,
          name: "Pet Record Sharing",
          checked: false,
        },
        {
          no: 3,
          name: "Routine Reminders",
          checked: true,
        },
        {
          no: 4,
          name: "Health Log Tracking",
          checked: true,
        },
        {
          no: 5,
          name: "Weekly Check In",
          checked: false,
        },
        {
          no: 6,
          name: "Vaccination Schedule",
          checked: false,
        },
        {
          no: 7,
          name: "Medication Schedule",
          checked: true,
        },
        {
          no: 8,
          name: "Pet Birthdays",
          checked: true,
        },
        {
          no: 9,
          name: "Lost and Found Pets",
          checked: true,
        },
        {
          no: 10,
          name: "Blood Donor Requests",
          checked: true,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  async mounted() {},
  methods: {
    getInputVal(val, check) {
      console.log(val, check);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
